import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
}; 

@NgModule({
  declarations: [
    // LoaderComponent,
    // ZoomImagesDirective,
    // HorizontalScrollDirective
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatCardModule,
    MatSliderModule,
    MatListModule,
    // NgxMaskDirective, 
    // NgxMaskPipe,
    MatTooltipModule,
    // NgOtpInputModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatTableModule,
    MatProgressBarModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    // QRCodeModule,
    // PipesModule,
    // RecaptchaModule,
    // FontAwesomeModule,
    // GoogleMapsModule,
    NgOptimizedImage,
    // NgxJsonLdModule
  ],
  exports: [
    
    // TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatCardModule,
    MatSliderModule,
    // NgxMaskDirective, 
    // NgxMaskPipe,
    MatTooltipModule,
    // QRCodeModule,
    // NgOtpInputModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatTableModule,
    MatProgressBarModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    // PipesModule,
    // LoaderComponent,
    // RecaptchaModule,
    // FontAwesomeModule,
    // ZoomImagesDirective,
    // HorizontalScrollDirective,
    // GoogleMapsModule,
    NgOptimizedImage,
    // NgxJsonLdModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {}},
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // },
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useValue: environment.language
    // },
    // { provide: MatPaginatorIntl, useClass: SettingPaginador },
    // provideImgixLoader(`${environment.domain}/assets/`),
    // provideNgxMask(),
    // AppShellNoRenderDirective
  ],
})
export class MaterialModule { 
  // constructor(library: FaIconLibrary){
  //   library.addIconPacks(fas, far, fab);
  // }
}